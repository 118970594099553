@import url('https://fonts.googleapis.com/css?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css?family=Assistant&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

.typing-text {
  font-family: Consolas;
  font-size: 3.5em;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  animation:
          typing 4s steps(50, end),
          blink-caret .75s step-end 6;
}

.navbar-brand {
  font-size: xxx-large;
  color: white;
  font-family: 'Lobster', serif;
}

.navbar {
  height: 12.5vh;
  min-height: 60px;
  background-color: #759CC9;
}

.nav-link {
  color: white;
  font-size: 1.5em;
}

.central {
  height: 87.5vh;
  min-width: 65vw;
}

.about {
  height: 87.5vh;
}

h1 {
  font-family: 'Assistant', Arial, serif;
  font-size: 7.5vmin;
}

h2 {
  font-family: 'Assistant', Arial, serif;
  font-size:  calc(10px + 2.25vw);
}

.border {
  border: none !important;
  border-radius: 60px;
}

.intro-text, .about-text {
  font-size: calc(5px + 2.5vmin);
  font-weight: bold;
}

.small-typing {
  display: none;
}

.home, .projects, .about {
  background-image: url("./assets/computer.jpg");
  background-size: cover;
}

.fas, .fab {
  color: black;
}

.project-text {
  font-size: 1.1em;
}

.project-text-short {
  display: none;
}

.info-text {
  font-size: 1.25em;
}

.project-img {
  box-shadow: 1em 1.5em 1.75em 0 rgba(0, 0, 0, 0.6), -1em 1.5em 1.75em 0 rgba(0, 0, 0, 0.6);
  max-width: 95%;
}

.middle-row {
  padding: calc(3px + 1.6em) 0 calc(3px + 1.6em) 0;
}

.full-background {
  background-color: rgba(117, 156, 201, 0.75);
}

.bracket {
  font-size: 13em;
}

.myimg {
  height: 50vh;
  width: auto;
}

@media screen and (max-width: 992px) {
  .home-full-background {
    background-color: rgba(117, 156, 201, 0.6);
    max-height: 100%;
  }

  .large-typing {
    display: none;
  }

  .small-typing {
    display: block;
  }

  .typing-text2 {
    font-family: Consolas;
    font-size: 3.5em;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    animation:
            typing 4s steps(50, end),
            blink-caret .75s step-end 6;
  }


  .background {
    max-height: 100%;
    border-radius: 0;
    background: none;
    overflow: hidden;
  }

  .full-background {
    max-height: 100%;
  }

  .project-link {
    font-size: calc(10px + 4vw);
  }

  .icons {
    flex-wrap: nowrap;
    /*font-size: calc(5px + 1vw);*/

  }

  .myimg {
    padding-top: 2em;
    height: auto;
    width: 20%;
    border-radius: 0 !important;
  }

  .about-text {
    font-size: 1em;
  }

  .bracket {
    display: none;
  }

  .navbar-brand {
    font-size: 5vw;
  }

  .project-info {
    flex-wrap: nowrap;
  }
}

@media screen and (max-height: 760px) and (max-width: 992px) {
  .myimg {
    display: none;
  }

  .project-text-short {
    display: block;
  }

  .project-text {
    display: none;
  }
}


@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  0% {
    border-right: .15em solid black;
    border-color: transparent;
  }
  50% {
    border-right: .15em solid black;
    border-color: black;
  }
}